<template>
  <div class="scientist_wrapper">
    <div>
      <div class="base_box node_box">
        <div class="switch_box both_side">
          <div>
            <div class="align_center delay">
              <img src="../assets/img/wifi.png" alt="" srcset="" />{{
                nodeTime
              }}
              ms
            </div>
            <div class="link">{{ rpcUrl }}</div>
          </div>
          <div class="switch_btn" @click="clickNode">切換節點</div>
        </div>
        <div class="base_title">選擇公鏈</div>
        <div class="base_select mt20" :class="{ select_active: show1 }">
          <div class="content">
            <img
              :src="chainObj[selectChain].logo"
              class="select_img"
              srcset=""
            />{{ chainObj[selectChain].chainName }}
          </div>
          <div class="right_down" @click.stop="show1 = !show1,show2=false"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div
              class="list_item"
              v-for="(item, index) in chains"
              @click="switchChain(item.chain)"
              :key="index"
            >
              <img :src="item.logo" class="select_img" srcset="" />{{
                item.chainName
              }}
            </div>
          </div>
        </div>
        <div class="base_title">選擇交易所</div>
        <div class="base_select mt20" :class="{ select_active: show2 }">
          <div class="content">
            <img
              :src="exchange[selectEx].logo"
              class="select_img"
              srcset=""
            />{{ exchange[selectEx].name }}
          </div>
          <div class="right_down" @click.stop="show2 = !show2,show1=false"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div
              class="list_item"
              v-for="(item, index) in exchange"
              @click="switchExchange(index)"
              :key="index"
            >
              <img :src="item.logo" class="select_img" srcset="" />{{
                item.name
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="base_box private_box">
        <div class="align_center">
          <div class="amount_item">
            <div class="title">{{ chainObj[selectChain].Symbol }} 餘額</div>
            <div class="text">{{ wallet.showBalance }}</div>
          </div>
          <div class="amount_item">
            <div class="title">USDT 餘額</div>
            <div class="text">{{ wallet.showUsdtBalance }}</div>
          </div>
          <div class="amount_item" v-if="tokenOutInfo.symbol">
            <div class="title">{{ tokenOutInfo.symbol }} 餘額</div>
            <div class="text">{{ wallet.showTokenBalance }}</div>
          </div>
        </div>
        <div class="expiree_time">
          &nbsp;&nbsp;•&nbsp;&nbsp;{{ $t("tool.22") }} {{ showEndTime }}
        </div>
        <div class="base_btn load_btn" @click="clickPrivateKey">載入私鑰</div>
        <div class="load_desc">
          <li>強烈推薦使用小額資產的錢包進行搶幣</li>
          <li>載入私鑰是為了省去確認操作，直接實現快速自動買賣</li>
        </div>
      </div>
    </div>
    <div class="base_box main_box">
      <div class="col_2 mt20">
        <div class="col_2_item">
          <div class="base_title">交易幣種</div>
          <div class="base_select2" :class="{ select_active2: show3 }">
            <img :src="selectToken.logo" class="select_img" srcset="" />
            <div>
              <div class="content">
                {{ selectToken.Symbol }}
                <div class="right_down" @click.stop="show3 = !show3"></div>
              </div>
              <div class="name">{{ selectToken.name }}</div>
            </div>
            <div class="downlist" v-click-outside="handleScroll">
              <div
                class="list_item"
                v-for="(item, index) in swapTokens"
                @click="selToken(index)"
                :key="index"
              >
                <img :src="item.logo" class="select_img" srcset="" />
                <div>
                  <div class="content">{{ item.Symbol }}</div>
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col_2_item">
          <div class="base_title">流動池</div>
          <div class="base_select2" :class="{ select_active2: show4 }">
            <img :src="selectPoolToken.logo" class="select_img" srcset="" />
            <div>
              <div class="content">
                {{ selectPoolToken.Symbol }}
                <div class="right_down" @click.stop="show4 = !show4"></div>
              </div>
              <div class="name">{{ selectPoolToken.name }}</div>
            </div>
            <div class="downlist" v-click-outside="handleScroll">
              <div
                class="list_item"
                v-for="(item, index) in Tokens"
                @click="selPoolToken(index)"
                :key="item.Symbol"
              >
                <img :src="item.logo" class="select_img" srcset="" />
                <div>
                  <div class="content">{{ item.Symbol }}</div>
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="base_title">合約地址</div>
      <div class="base_flex mt20">
        <div class="base_input">
          <input
            type="text"
            v-model="tokenOut"
            @blur="confirmTokenOut"
            placeholder="请輸入合約地址"
          />
        </div>
        <div class="base_btn approve_btn" @click="clickApprove">提前授權</div>
      </div>
      <div class="base_title">流動池最小數量（USDT）</div>
      <div class="base_input mt20" @click="inputTip">
        <input
          type="text"
          v-model="tokenUValue"
          :disabled="gasMode"
          placeholder="不填寫默認0，不進行檢測"
        />
      </div>
      <div class="base_title">金額（{{selectToken.Symbol}}）</div>
      <div class="base_input mt20">
        <input type="text" v-model="amountIn" placeholder="輸入買入金額" />
      </div>
      <div class="base_title align_center">
        次數
        <Tooltip max-width="300" :content="$t('scientist.1', [chainObj[selectChain].Symbol])" placement="top">
          <img src="../assets/img/question.png" class="quest" srcset />
        </Tooltip>
      </div>
      <div class="base_input mt20" @click="inputTip">
        <input
          type="text"
          :disabled="gasMode"
          
          v-model="tradeNum"
          placeholder="请輸入買入次數，默認1"
        />
      </div>
      <!-- <div class="demo_box mt20">
        <li>示例：</li>
        <div>
          {{ $t("tool.422", [chainObj[selectChain].Symbol]) }}
        </div>
      </div> -->
      <div class="base_title">搶購速度</div>
      <div style="padding: 0 7px" class="mt20">
        <vue-slider :tooltip-formatter="formatter1" :min="1" :interval="0.1" v-model="gasMulti" height="8px"></vue-slider>
      </div>
      <div class="base_tips">
        <img src="../assets/img/info2.png" class="base_info2" srcset="" />{{
          $t("tool.44", [
            gasMulti,
            formatDecimal(gasPrice * gasMulti) + chainObj[selectChain].Symbol,
            formatDecimal(gasPrice * gasMulti * 2) + chainObj[selectChain].Symbol,
          ])
        }}
      </div>
      <div class="">
        <div class="align_center space_betw mt20 mobile_br">
          <!-- Hash检查 -->
          <div class="switch_box mboile_20">
            <span class="name">{{ $t("scientist.4") }}</span>
            <Tooltip max-width="300" :content="$t('scientist.5')" placement="top">
              <img src="../assets/img/question.png" class="quest" srcset />
            </Tooltip>
            <i-switch
              size="large"
              :disabled="gasMode"
              @click.native="inputTip"
              v-model="status"
              @on-change="change"
            />
          </div>
          <!-- 延迟下单(毫秒) -->
          <div class="switch_box inputset_box2 mobile_br">
            <div class="align_center mboile_20">
              <span class="name">{{ $t("scientist.2") }}</span>
              <Tooltip max-width="300" :content="$t('scientist.3')" placement="top">
                <img src="../assets/img/question.png" class="quest" srcset />
              </Tooltip>
              <i-switch
                size="large"
                :disabled="gasMode"
                @click.native="inputTip"
                v-model="delayStatus"
                @on-change="changeDelay"
              />
            </div>
            <div class="base_input mboile_0" v-show="delayStatus">
              <input
                type="text"
                v-model="delayInterval"
                placeholder="0ms"
                onkeyup="value=value.replace(/[^a-z0-9_]/g,'');"
              />
            </div>
          </div>
        </div>
        <div class="col_2 mt20 mobile_br">
          <div class="col_2_item mboile_20">
            <!-- 监控(毫秒) -->
            <div class="switch_box inputset_box">
              <span class="name">{{ $t("scientist.6") }}</span>
              <Tooltip max-width="300" :content="$t('scientist.7')" placement="top">
                <img src="../assets/img/question.png" class="quest" srcset />
              </Tooltip>
              <div class="base_input ml10" @click="inputTip">
                <input
                  type="text"
                  v-model="rotationInterval"
                  :disabled="gasMode"
                  onkeyup="value=value.replace(/[^a-z0-9_]/g,'');"
                />
              </div>
            </div>
          </div>
          <div class="col_2_item mboile_0">
            <!-- 买入失效(秒) -->
            <div class="switch_box">
              <span class="name">{{ $t("scientist.8") }}</span>
              <Tooltip max-width="300" :content="$t('scientist.9')" placement="top">
                <img src="../assets/img/question.png" class="quest" srcset />
              </Tooltip>
              <div class="base_input ml10" @click="inputTip">
                <input
                  type="text"
                  v-model="failTime"
                  :disabled="gasMode"
                  onkeyup="value=value.replace(/[^a-z0-9_]/g,'');"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="base_title align_center">
        {{ $t("scientist.17") }}
        <Tooltip max-width="300" :content="$t('scientist.10')" placement="top">
          <img src="../assets/img/question.png" class="quest" srcset />
        </Tooltip>
      </div>
      <div class="base_input mt20">
        <input
          type="text"
          v-model="receiveAmount"
          :placeholder="$t('scientist.18')"
        />
      </div>
      <div class="col_2 mt20">
        <div class="col_2_item">
          <div class="base_title">{{ $t("scientist.19") }}</div>
          <div class="base_input">
            <input type="text" v-model="priceUp" :placeholder="$t('scientist.13')"/>
          </div>
        </div>
        <div class="col_2_item">
          <div class="base_title">{{ $t("scientist.20") }}</div>
          <div class="base_input">
            <input type="text" v-model="saleRate" :placeholder="$t('scientist.14')" />
          </div>
        </div>
      </div>
      <!-- 燃Gas模式 -->
      <div class="col_2 mt20 mobile_br">
        <div class="col_2_item mboile_20">
          <div class="switch_box">
            <span class="name">{{ $t("buy.9") }}</span>
            <Tooltip max-width="300" :content="$t('scientist.11')" placement="top">
              <img src="../assets/img/question.png" class="quest" srcset />
            </Tooltip>
            <i-switch
              size="large"
              v-model="gasMode"
              @on-change="changeGasMode"
            />
          </div>
        </div>
        <div class="col_2_item mboile_0" :style="{visibility:gasMode?'visible':'hidden'}">
          <!-- 间隔时间(毫秒) -->
          <div class="switch_box">
            <span class="name">{{ $t("scientist.12") }}</span>

            <div class="base_input ml10">
              <input
                type="text"
                v-model="gasInterval"
                placeholder="0ms"
                onkeyup="value=value.replace(/[^a-z0-9_]/g,'');"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="base_btn2" @click="panicBuying">
        {{ $t("vip.4") }}
      </div>
      <div class="base_btn2" v-if="isLoading" @click="stopBuy">
        {{ $t("tool.55") }}
      </div>
    </div>
    <Modal
      v-model="nodeModal"
      class-name="node_modal"
      footer-hide
      :closable="false"
      width="600"
    >
      <div class="modal_header">
        <div class="modal_title">
          <img src="../assets/img/node_icon.png" alt="" srcset="" />切換節點
        </div>
        <img
          src="../assets/img/vipclose.png"
          class="close_icon"
          srcset
          @click="nodeModal = false"
        />
      </div>
      <div class="align_center">
        <div class="base_input" style="flex: 1">
          <input type="text" v-model="nodeUrl" :placeholder="$t('tool.14')" />
        </div>
        <span
          class="base_btn"
          style="width: 100px; margin-left: 20px"
          @click="switchNode"
          >確認</span
        >
      </div>
    </Modal>
    <Modal
      v-model="keyModal"
      class-name="node_modal"
      footer-hide
      :closable="false"
      width="600"
    >
      <div class="modal_header">
        <div class="modal_title">
          <img src="../assets/img/private_icon.png" alt="" srcset="" />加載私鑰
        </div>
        <img
          src="../assets/img/vipclose.png"
          class="close_icon"
          srcset
          @click="keyModal = false"
        />
      </div>
      <div class="align_center">
        <div class="base_input" style="flex: 1">
          <input
            :type="password?'text':'password'"
            v-model="privateKey"
            :placeholder="$t('tool.14')"
          />
          <img src="../assets/img/er_hide.png" class="er_img" v-show="password" @click="password=!password" alt="" srcset="">
          <img src="../assets/img/er_show.png" class="er_img" v-show="!password" @click="password=!password" alt="" srcset="">
        </div>
        <span
          class="base_btn"
          style="width: 100px; margin-left: 20px"
          @click="confirmPrivateKey"
          >確認</span
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import BN from "bn.js";
import {
  VipSale_ABI,
  VipSale,
  tokenABI,
  ERC20_ABI,
  Common_ABI,
  SwapRouter_ABI,
  ZERO_ADDRESS,
  MAX_INT,
  CheckVipRpc
} from "../config";
import {
  showFromWei,
  showFromWei2,
  showAccount,
  formatDecimal,
  multiply,
  showFromWeiMore,
  toWeiMore,
  toWei,
  parseTime,
  clickOutside
} from "../utils";
import { chainObj2 as chainObj } from "../config";
var startTime = 0,
  curBuy = 0,
  buyMsg = null;

export default {
  	directives: {clickOutside},
props: {
    address: {
      type: String,
    },
    chainId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      formatter1: '{value}倍',
      password: false,
      chainObj,
      selectEx: 0,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      selectChain: "BSC",
      rpcUrl: chainObj["BSC"].RPC,
      nodeModal: false,
      keyModal: false,
      nodeUrl: "",
      privateKey: "",
      nodeTime: 212,

      //兑换的币种数组，一般是主币和USDT
      swapTokens: chainObj["BSC"].Tokens.slice(0, 2),
      //当前选择的兑换代币，一般是主币或者USDT
      selectToken: chainObj["BSC"].Tokens[0],
      selectPoolToken: chainObj["BSC"].Tokens.slice(0, 2)[0],
      //价值币数组，用于查价格或者代币滑点
      Tokens: chainObj["BSC"].Tokens,
      //输入框代币合约地址
      tokenOut: null,
      //输入框代币合约对应的代币信息
      tokenOutInfo: {},

      //路由
      swapRouter: chainObj["BSC"].Dexs[0].SwapRouter,
      //当前选择的链配置
      chainConfig: chainObj["BSC"],
      USDTDetail: chainObj["BSC"].Tokens[1],

      //是否Vip
      isVip: false,
      showEndTime: "-",

      wallet: {},

      value: 0,

      // 交易次数
      tradeNum: null,
      //交易数量
      amountIn: null,
      //交易滑点，默认20%
      slige: null,
      //gas倍数，默认1倍
      gasMulti: 1,

      gasPrice: null,
      // 滑点
      amountOut: null,
      //价格上涨，百分比
      priceUp: null,
      //卖出百分比
      saleRate: null,
      //池子大小，USD计价
      tokenUValue: null,
      //兑换接收代币数量
      receiveAmount: null,
      // 买入失效(秒)
      failTime: 20,
      // 延迟下单
      delayStatus: false,
      delayInterval: 1500,
      // 燃gas模式开关
      gasMode: false,
      // 燃烧间隔
      gasInterval: 1000,

      funStatus: false,
      status: false,
      rotationInterval: 1000,

      isFirst: true,
      checking: false,
      //使用固定的gasLimit估算手续费
      gasLimit: "1000000",

      //刷新代币信息，在这里主要刷新代币池子大小
      _refreshCheckBuyIntervel: null,
      payAmount: 0,
      thenSell: false, // 需要卖出不

      tradeMsg: null,
      buyMsg: null,
      isLoading: false,
    };
  },
  computed: {
    chains() {
      return Object.values(this.chainObj);
    },
    exchange() {
      return this.chainObj[this.selectChain].Dexs;
    },
    
  },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll, true);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.handleScroll, true);
  // },
  watch: {
    address() {
      if (this.address) {
        this.getTime();
      }
    },
  },
  mounted() {
    if (this.address) {
      this.getTime();
    }
  },
  methods: {
    formatDecimal,
    handleScroll() {
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
    },
    clickNode() {
      this.nodeModal = true;
    },
    clickPrivateKey() {
      this.keyModal = true;
    },
    async getTime() {
      var start = new Date().getTime();

      var Web3 = require("web3");
      console.log(this.selectChain)
      let rpcUrl = this.rpcUrl
      if(this.selectChain == 'OP') {
        rpcUrl = 'https://ethereum.publicnode.com'
      }
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));

      let gasPrice = await myWeb3.eth.getGasPrice();
      
      this.gasPrice = multiply(myWeb3.utils.fromWei(gasPrice), this.gasLimit)
      if(this.selectChain == 'OP') {
        this.gasPrice = multiply(this.gasPrice,0.05)
      }
      var end = new Date().getTime();
      this.nodeTime = end - start;
    },
    checkURL(URL) {
      var str = URL,
        Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
        objExp = new RegExp(Expression);
      if (objExp.test(str) == true) {
        return true;
      } else {
        return false;
      }
    },
    async switchNode() {
      if (!this.nodeUrl) {
        this.$message.error("請輸入节点地址");
        return;
      }
      if (!this.checkURL(this.nodeUrl)) {
        this.$message.error("無效的 JSON RPC 響應");
        return;
      }
      try {
        var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.nodeUrl));
        console.log("myWeb3", myWeb3);

        let time = await myWeb3.eth.getGasPrice();
        console.log(time);
        this.rpcUrl = this.nodeUrl;
        this.getTime();
        this.nodeModal = false;
        this.$message(this.$t("lang.success1"));
      } catch (e) {
        console.log(e);
        this.$message.error(e.message);
      }
    },
    // 切换链
    switchChain(chain) {
      this.selectChain = chain;
      this.chainConfig = chainObj[chain];

      this.selectToken = chainObj[chain].Tokens[0];
      this.Tokens = chainObj[chain].Tokens;
      this.swapRouter = chainObj[chain].Dexs[0].SwapRouter;
      this.USDTDetail = chainObj[chain].Tokens[1];
      this.rpcUrl = chainObj[chain].RPC;
      this.gasMulti = 1;
      this.swapTokens = chainObj[chain].Tokens.slice(0, 2);

      this.selectPoolToken = chainObj[chain].Tokens[0];

      this.getTime();
      if (this.wallet.privateKey) {
          this.getWalletBalance();
        }
      this.show1 = false;
    },
    // 选择交易所
    switchExchange(index) {
      console.log(this.exchange);
      this.selectEx = index;
      this.swapRouter = this.exchange[index].SwapRouter;
      this.show2 = false;
    },
    //选择交易币种
    selToken(index) {
      this.selectToken = this.swapTokens[index];
      this.show3 = false;
    },
    //选择指定池子
    selPoolToken(index) {
      this.selectPoolToken = this.Tokens[index];
      this.show4 = false;
    },
    inputTip() {
      if (this.gasMode) return this.$message.error("燒Gas模式下不可用");
    },
    changeDelay(status) {
      this.delayStatus = status;
    },
    changeGasMode(status) {
      this.gasMode = status;
      if (status) {
        // 流动池最小数量、Hash检查、监控、买入失效、延迟下单
        this.tokenUValue = null;
        this.delayStatus = false;
        this.status = false;
        this.tradeNum = null;
        this.failTime = null
        this.rotationInterval = null
      } else {
        this.failTime = 20
        this.rotationInterval = 1000
      }
    },
    change(status) {},
    //确定私钥
    async confirmPrivateKey() {
      let privateKey = this.privateKey;
      if (!privateKey) {
        return;
      }

      var Web3 = require("web3");
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
      try {
        //私钥推导账户信息
        let account = myWeb3.eth.accounts.privateKeyToAccount(privateKey);
        let wallet = {
          //私钥
          privateKey: privateKey,
          //地址
          address: account.address,
          //主币余额
          showBalance: 0,
          //USDT余额
          showUsdtBalance: 0,
          showTokenBalance: 0,
        };
        //当前钱包
        console.log("私钥钱包", wallet);
        this.wallet = wallet;
        this.keyModal = false;
        this.getWalletBalance();
      } catch (e) {
        console.log("confirmPrivateKey", e.message);
        if(e.message.indexOf('Private key must be 32') > -1) {
          this.$message.error('私鑰必須是 32 字節長');
        } else {
          this.$message.error(e.message);

        }
      }
    },
    //确定要购买的代币合约
    async confirmTokenOut() {
      let tokenAddress = this.tokenOut;
      if (!tokenAddress) {
        this.$message.error("請輸入要购买的代币合约");
        return;
      }
      const msg = this.$message({
        type: "loading",
        message: "loading",
        duration: 0,
      });
      try {
        var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
        const commonContract = new myWeb3.eth.Contract(
          Common_ABI,
          this.chainConfig.Common
        );

        //获取要购买的代币信息
        let tokensInfo = await commonContract.methods
          .getTokenInfo(tokenAddress)
          .call();
        let symbol = tokensInfo[0];
        let decimals = tokensInfo[1];
        let totalSupply = tokensInfo[2];

        let tokenOutInfo = {
          address: tokenAddress,
          symbol: symbol,
          decimals: decimals,
          totalSupply: totalSupply,
          showTotal: showFromWei(totalSupply, decimals, 2),
        };
        console.log("tokenOutInfo", tokenOutInfo);

        //获取价格
        let priceInfo = await this.getTokenPrice(tokenOutInfo);
        console.log(priceInfo);

        this.tokenOutInfo = priceInfo;

        //获取钱包余额
        if (this.wallet.privateKey) {
          this.getWalletBalance();
        }
      } catch (e) {
        console.log("e", e);
        this.tokenOutInfo = {}
        this.$message.error('無效地址');
      } finally {
        msg.close();
      }
    },
    //检测是否VIP，需要用检测VIP的RPC，该RPC，总是BSC链的RPC
    async checkVip() {
      try {
        let wallet = this.wallet;
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(CheckVipRpc)
        );
        const saleContract = new myWeb3.eth.Contract(VipSale_ABI, VipSale);
        //获取用户信息
        const userInfo = await saleContract.methods
          .getUserInfo(wallet.address)
          .call();
        console.log("userInfo", userInfo);
        //Vip过期时间
        let endTime = userInfo[1];
        let showEndTime,
            isVip = true;

          if (new BN(endTime, 10).eq(new BN(MAX_INT, 10))) {
            showEndTime = "永久会员";
          } else if (parseInt(endTime) == 0) {
            showEndTime = "未购买会员";
            isVip = false;
          } else {
            showEndTime = parseTime(parseInt(endTime));
            if(parseInt(new Date().getTime() / 1000) > parseInt(endTime)) {
              isVip = false
            }
          }
        this.isVip = isVip;
        this.showEndTime = showEndTime;
        console.log("this.isVip", this.isVip);
      } catch (e) {
        console.log("e", e);
      } finally {
      }
    },
    //获取钱包主币、USDT余额
    async getWalletBalance() {
      try {
        let wallet = this.wallet;
        var Web3 = require("web3");
        console.log("this.rpcUrl", this.rpcUrl);

        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
        //获取USDT余额
        console.log(
          "this.chainObj[this.selectChain].USDT",
          this.chainObj[this.selectChain].USDT
        );
        console.log(this.selectChain);
        const usdtContract = new myWeb3.eth.Contract(
          ERC20_ABI,
          this.chainObj[this.selectChain].USDT
        );
        console.log("wallet.address", wallet.address);
        let usdtBalance = await usdtContract.methods
          .balanceOf(wallet.address)
          .call();
        wallet.usdtBalance = new BN(usdtBalance, 10);
        console.log("usdtBalance", usdtBalance);
        let showUsdtBalance = showFromWei(
          usdtBalance,
          this.USDTDetail.decimals,
          4
        );
        wallet.showUsdtBalance = showUsdtBalance;
        
        
        console.log("showUsdtBalance", showUsdtBalance);
        //获取主币余额
        let balance = await myWeb3.eth.getBalance(wallet.address);
        let showBalance = showFromWei(balance, 18, 4);
        wallet.balance = new BN(balance, 10);
        wallet.showBalance = showBalance;
        let tokenOutInfo = this.tokenOutInfo;
        console.log("tokenOutInfo", tokenOutInfo);
        if (tokenOutInfo && tokenOutInfo.address) {
          //获取代币余额
          const tokenContract = new myWeb3.eth.Contract(
            ERC20_ABI,
            tokenOutInfo.address
          );
          let tokenBalance = await tokenContract.methods
            .balanceOf(wallet.address)
            .call();
            wallet.tokenBalance = new BN(tokenBalance, 10);
          let showTokenBalance = showFromWei(
            tokenBalance,
            tokenOutInfo.decimals,
            4
          );
          console.log("tokenBalance", tokenBalance);
          console.log("tokenOutInfo.decimals", showFromWei(
            tokenBalance,
            tokenOutInfo.decimals,
            6
          ));

          
          wallet.showTokenBalance = showTokenBalance;
        }
        this.wallet = wallet;
        for(let key in wallet) {
          this.$set(this.wallet, key, wallet[key])
        }
        // this.wallet = wallet;

        this.checkVip();
      } catch (e) {
        console.log("e", e);
      } finally {
      }
    },
    //获取其他价值币的合约地址，用于调用合约
    _getTokensAddress() {
      let Tokens = this.Tokens;
      let len = Tokens.length;
      let tokensAddress = [];
      for (let i = 0; i < len; i++) {
        tokensAddress.push(Tokens[i].address);
      }
      return tokensAddress;
    },
    stopBuy() {
      this.isLoading = false;
      this.message.close();
      // if (buyMsg) {
      //   buyMsg();
      // }
      this.clearCheckBuyInterval();
    },
    //获取代币价格
    async getTokenPrice(tokenInfo) {
      var Web3 = require("web3");
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
      const commonContract = new myWeb3.eth.Contract(
        Common_ABI,
        this.chainConfig.Common
      );
      //获取要购买的代币价格
      let tokens = this._getTokensAddress();
      let tokenPriceResult = await commonContract.methods
        .getTokenPrice(
          this.swapRouter,
          tokenInfo.address,
          this.chainConfig.USDT,
          tokens
        )
        .call();
      console.log("tokenPriceResult", tokenPriceResult);
      //代币价格，需要处理USDT最小精度
      let tokenPrice = new BN(tokenPriceResult[0], 10);
      //价格的精度，本来就需要处理USDT的精度，这个精度是在USDT精度的基础上多的，还要再处理一遍
      //价格小于0.{17个0}x时，才存在这个精度
      let priceDecimals = parseInt(tokenPriceResult[1]);
      //池子中另一个代币的合约，一般是USDT或者主币对应的代币
      let pairOther = tokenPriceResult[2];
      //池子里的代币数量
      let tokenReserve = new BN(tokenPriceResult[3], 10);
      //池子里代币的U价值
      let tokenUValue = tokenReserve
        .mul(tokenPrice)
        .div(toWei("1", tokenInfo.decimals))
        .div(toWei("1", priceDecimals));

      tokenInfo.tokenPrice = tokenPrice;
      tokenInfo.priceDecimals = priceDecimals;
      tokenInfo.pairOther = pairOther;
      tokenInfo.tokenReserve = tokenReserve;
      tokenInfo.tokenUValue = tokenUValue;

      let realDecimals = this.USDTDetail.decimals + priceDecimals;
      tokenInfo.showTokenPrice = showFromWeiMore(
        tokenPrice,
        realDecimals,
        realDecimals
      );
      return tokenInfo;
    },
    clearCheckBuyInterval() {
      if (this._refreshCheckBuyIntervel) {
        clearInterval(this._refreshCheckBuyIntervel);
        this._refreshCheckBuyIntervel = null;
      }
      //  if (this.buyMsg) {
      //   this.buyMsg()
      //   this.buyMsg = null;
      // }
      //  if (this.tradeMsg) {
      //   this.tradeMsg()
      //   this.tradeMsg = null;
      // }
    },
    async clickApprove() {
      if (!this.privateKey) {
        this.$message.error("請輸入私钥导入钱包");
        return;
      }
      if (!this.isVip) {
        this.$message.error("仅限VIP使用");
        return;
      }
      if (!this.tokenOut) {
        this.$message.error("請輸入代币合约地址");
        return;
      }
      let selectToken = this.selectToken;

      if (selectToken.address == this.chainConfig.WETH) {
        //主币余额不足
        // if (this.wallet.balance.lte(toWei(this.amountIn, selectToken.decimals))) {
        //     this.$message.error(selectToken.Symbol + '余额不足');
        //     this.isLoading = false;
        //     this.isLoadingFun()
        //     return;
        // }
        // this.$message.error("无需授权");
      } else {
        //USDT余额不足
        // if (this.wallet.usdtBalance.lt(toWei(this.amountIn, selectToken.decimals))) {
        //     this.$message.error('USDT余额不足');
        //     this.isLoading = false;
        //     this.isLoadingFun()
        //     return;
        // }
        await this.approve(this.USDTDetail.address, true);
      }
      await this.approve(this.tokenOut, true);
    },
    async approve(tokenAddress, flag) {
      let wallet = this.wallet;
      if (!wallet || !wallet.privateKey) {
        this.$message.error("请先输入私钥导入钱包");
        return;
      }
      let msg = null;

      try {
        var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
        const tokenContract = new myWeb3.eth.Contract(ERC20_ABI, tokenAddress);
        let allowance = await tokenContract.methods
          .allowance(wallet.address, this.swapRouter)
          .call();
        allowance = new BN(allowance, 10);
        if (!allowance.isZero()) {
          if (flag) {
            this.$message.error("已授权");
          }
          return;
        }
        var gasPrice = await myWeb3.eth.getGasPrice();
        gasPrice = new BN(gasPrice, 10);
        if (flag) {
          this.message = this.$message({
            type: "loading",
            message: "loading",
            duration: 0,
          });
        }
        var gas = await tokenContract.methods
          .approve(this.swapRouter, MAX_INT)
          .estimateGas({ from: wallet.address });
        gas = new BN(gas, 10).mul(new BN("120", 10)).div(new BN("100", 10));

        //Data
        var data = tokenContract.methods
          .approve(this.swapRouter, new BN(MAX_INT, 10))
          .encodeABI();

        var nonce = await myWeb3.eth.getTransactionCount(
          wallet.address,
          "pending"
        );

        var txParams = {
          gas: Web3.utils.toHex(gas),
          gasPrice: Web3.utils.toHex(gasPrice),
          nonce: Web3.utils.toHex(nonce),
          chainId: this.chainConfig.ChainId,
          value: Web3.utils.toHex("0"),
          to: tokenAddress,
          data: data,
          from: wallet.address,
        };

        var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));

        console.log("txParams", txParams);

        //交易签名
        let privateKey = wallet.privateKey.trim();
        var signedTx = await myWeb3.eth.accounts.signTransaction(
          txParams,
          privateKey
        );
        console.log("signedTx", signedTx);
        let transaction = await myWeb3.eth.sendSignedTransaction(
          signedTx.rawTransaction
        );
        //交易失败
        if (!transaction.status) {
          this.$message.error(this.$t("lang.a4"));
          return;
        }
        this.$message("授权成功");
      } catch (e) {
        console.log("e", e);
      } finally {
        if (flag && this.message) {
          this.message.close();
        }
      }
    },
    //抢购
    async panicBuying() {
      this.payAmount = new BN(0);
      this.clearCheckBuyInterval();

      if (!this.privateKey) {
        this.$message.error("請輸入私钥导入钱包");
        return;
      }
      if (!this.isVip) {
        this.$message.error("仅限VIP使用");
        return;
      }
      if (!this.amountIn) {
        this.$message.error("請輸入买入金额");
        return;
      }
      if (!this.tokenOutInfo || !this.tokenOutInfo.symbol) {
        this.$message.error("請輸入代币合约地址");
        return;
      }
      if (this.priceUp && this.saleRate) {
        var re = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
        if (!re.test(this.priceUp)) {
          this.$message.error("請輸入正确的价格上涨");
          return;
        }
        if (!re.test(this.saleRate)) {
          this.$message.error("請輸入正确的卖出比例");
          return;
        }
        this.thenSell = true;
      } else {
        this.thenSell = false;
      }
      let tradeNum = this.tradeNum;
      if (!tradeNum) {
        tradeNum = 1;
        curBuy = 0;
      }
      this.isLoading = true;
      this.message = this.$message({
        type: "loading",
        message: "loading",
        duration: 0,
      });

      //使用之后，再刷新会员状态
      this.checkVip();
      let selectToken = this.selectToken;

      if (selectToken.address == this.chainConfig.WETH) {
        //主币余额不足
        if (
          this.wallet.balance.lte(toWei(this.amountIn, selectToken.decimals))
        ) {
          this.$message.error(selectToken.Symbol + "余额不足");
          this.isLoading = false;
          this.message.close();
          return;
        }
      } else {
        //USDT余额不足
        if (
          this.wallet.usdtBalance.lt(toWei(this.amountIn, selectToken.decimals))
        ) {
          this.$message.error("USDT余额不足");
          this.isLoading = false;
          this.message.close();
          return;
        }
        await this.approve(this.USDTDetail.address, false);
      }
      buyMsg = null;

      if (!this.gasMode) {
        this._refreshCheckBuyIntervel = setInterval(() => {
          this._panicBuying();
        }, this.rotationInterval);
      } else {
        this._refreshCheckBuyIntervel = setInterval(() => {
          this._panicBuying();
        }, this.gasInterval);
      }
    },
    //刷新代币信息，这里主要代币池子大小
    async _panicBuying() {
      if (this.checking) {
        console.log("checking");
        return;
      }
      this.checking = true;
      try {
        this._buy();
      } catch (e) {
        this.isLoading = false;
        this.message.close();
        console.log("e", e);
      } finally {
        this.checking = false;
      }
    },
    //购买过程
    async _buy() {
      // if (!this.gasMode && !buyMsg) {
      //   buyMsg = this.$Message.loading({
      //     content: "检测中...",
      //     duration: 0,
      //   });
      // }

      try {
        
        let wallet = this.wallet;

        //池子代币，烧gas模式用指定池子，否则用检测到的池子
        let pairOther = this.selectPoolToken.address;

        //不是烧gas模式，才检测池子大小
        if (!this.gasMode) {
          console.log("=========getTokenPrice=======");
          //自动检测池子
          let tokenInfo = await this.getTokenPrice(this.tokenOutInfo);
          console.log("=========getTokenPrice2=======");
          this.tokenOutInfo = tokenInfo;
          //检测的交易对池子
          pairOther = tokenInfo.pairOther;
          //检测池子大小
          let tokenUValueInput = this.tokenUValue;
          if (tokenUValueInput) {
            tokenUValueInput = toWei(
              tokenUValueInput,
              this.USDTDetail.decimals
            );
            //池子大小，USD
            let tokenUValue = tokenInfo.tokenUValue;
            //池子不满足要求
            if (tokenUValue.lt(tokenUValueInput)) {
              return;
            }
          }
        }
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl)
        );
        console.log("========SwapRouter_ABI========", this.swapRouter);
        const swapContract = new myWeb3.eth.Contract(
          SwapRouter_ABI,
          this.swapRouter
        );
        let amountIn;
        let path = [];
        //当前选择的交易币种
        let selectToken = this.selectToken;
        //当前代币合约信息
        let tokenOutInfo = this.tokenOutInfo;

        //输入
        amountIn = toWei(this.amountIn, selectToken.decimals);
        //路径，指定支付代币
        path.push(selectToken.address);

        //选择的代币和池子代币不一样时
        if (pairOther != selectToken.address) {
          path.push(pairOther);
        }
        path.push(tokenOutInfo.address);

        //最小可得代币数量
        let amountOutMin = this.receiveAmount;
        if (!amountOutMin) {
          console.log("amountOutMin", amountOutMin);
          amountOutMin = new BN(0);
        } else {
          amountOutMin = toWei(amountOutMin, tokenOutInfo.decimals);
        }

        var gasPrice = await myWeb3.eth.getGasPrice();
        gasPrice = new BN(gasPrice, 10);
        //gas倍数
        let gasMulti = this.gasMulti;
        if (!gasMulti) {
          gasMulti = 1;
        }
        gasMulti = parseFloat(gasMulti);
        gasMulti = parseInt(gasMulti * 100);
        gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));
        let failTime = this.failTime;
        if (!failTime) {
          failTime = 60;
        }
        failTime = (await myWeb3.eth.getBlock("latest")).timestamp + failTime;
        if (!this.gasMode) {
          failTime = failTime + Math.round(this.delayInterval / 1000);
        }
        console.log("===================")
        console.log(amountOutMin,
              path,
              wallet.address,
              failTime)
        //Data
        let data;
        //主币购买
        if (selectToken.address == this.chainConfig.WETH) {
          data = swapContract.methods
            .swapExactETHForTokensSupportingFeeOnTransferTokens(
              amountOutMin,
              path,
              wallet.address,
              failTime
            )
            .encodeABI();
        } else {
          data = swapContract.methods
            .swapExactTokensForTokensSupportingFeeOnTransferTokens(
              amountIn,
              amountOutMin,
              path,
              wallet.address,
              failTime
            )
            .encodeABI();
        }

        var nonce = await myWeb3.eth.getTransactionCount(
          wallet.address,
          "pending"
        );
        console.log("nonce", nonce);

        //不是烧gas模式，通过预估手续费，检测交易是否成功
        if (!this.gasMode) {
          console.log("!gasMode");
          let gas;
          //主币购买
          if (selectToken.address == this.chainConfig.WETH) {
            console.log(amountOutMin, path, wallet.address, amountIn);
            gas = await swapContract.methods
              .swapExactETHForTokensSupportingFeeOnTransferTokens(
                amountOutMin,
                path,
                wallet.address,
                failTime
              )
              .estimateGas({ from: wallet.address, value: amountIn });
          } else {
            gas = await swapContract.methods
              .swapExactTokensForTokensSupportingFeeOnTransferTokens(
                amountIn,
                amountOutMin,
                path,
                wallet.address,
                failTime
              )
              .estimateGas({ from: wallet.address });
          }
        }

        //这里gasLimit直接用200万
        let gas = new BN(this.gasLimit, 10);

        let value = "0";
        if (selectToken.address == this.chainConfig.WETH) {
          value = amountIn;
        }

        var txParams = {
          gas: Web3.utils.toHex(gas),
          gasPrice: Web3.utils.toHex(gasPrice),
          nonce: Web3.utils.toHex(nonce),
          chainId: this.chainConfig.ChainId,
          value: Web3.utils.toHex(value),
          to: this.swapRouter,
          data: data,
          from: wallet.address,
        };

        //gas费
        var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));
        console.log("fee", Web3.utils.fromWei(fee, "ether"));

        if (!this.gasMode) {
          this.clearCheckBuyInterval();
          let delayInterval = this.delayInterval - this.rotationInterval * 2;
          if (delayInterval < 0) {
            delayInterval = this.delayInterval;
          }
          // 延迟下单
          setTimeout(async () => {
            await this._buyTx(myWeb3, wallet, txParams, amountIn);
          }, delayInterval);
        } else {
          await this._buyTx(myWeb3, wallet, txParams, amountIn);
        }
      } catch (e) {
        console.log("e", e);
      } finally {
      }
    },
    // beforeOrder(myWeb3, wallet, txParams, amountIn) {
    //   var d2 = new Date().getTime();
    //   if(parseInt(startTime - d2) <= this.delayInterval) {
    //     console.log(111111)
    //       this._buyTx(myWeb3, wallet, txParams, amountIn);
    //   } else {
    //     console.log(22222)
    //     setTimeout(() => {
    //       this.beforeOrder(myWeb3, wallet, txParams, amountIn)
    //     }, 1000)
    //   }
    // },
    async _buyTx(myWeb3, wallet, txParams, amountIn) {
      if (!this.gasMode) {
        // let endTime = new Date().getTime();
        // if (endTime - startTime > this.failTime * 1000) {
        //   this.isLoading = false;
        //   this.isLoadingFun();
        //   this.clearCheckBuyInterval();
        //   if (buyMsg) {
        //     buyMsg();
        //   }
        //   return;
        // }
      }
      // if (!this.gasMode && buyMsg) {
      //   buyMsg();
      // }
      // if(!this.tradeMsg) {
      //   this.tradeMsg = this.$Message.loading({
      //     content: "交易中...",
      //     duration: 0,
      //   });
      // }

      try {
        //交易签名
        let privateKey = wallet.privateKey;
        var signedTx = await myWeb3.eth.accounts.signTransaction(
          txParams,
          privateKey
        );
        //发起购买，删除定时器，烧gas模式不删除定时器
        if (!this.gasMode) {
          this.clearCheckBuyInterval();
        }
        let transaction = await myWeb3.eth.sendSignedTransaction(
          signedTx.rawTransaction
        );
        // 交易失败
        if (!transaction.status) {
          this.$message.error("购买失败");
          return;
        }

        //购买成功删除定时器
        this.clearCheckBuyInterval();

        this.$message("购买成功");
        //统计购买支付数量
        this.payAmount = this.payAmount.add(amountIn);
        curBuy = curBuy + 1;
        await this.getWalletBalance(wallet);
        if (!this.gasMode && this.tradeNum) {
          if (curBuy < this.tradeNum) {
            this._buy();
            return;
          }
        }
        //需要卖出
        if (this.thenSell) {
          this.checkSell();
        } else {
          this.isLoading = false;
          this.message.close();
        }
        // if(this.tradeMsg) {
        //   this.tradeMsg()
        //   this.tradeMsg = null
        // }
      } catch (e) {
        console.log("e", e);
        // this.$message.error(e);
        // this.isLoading = false;
        //   this.isLoadingFun();
      } finally {
      }
    },
    async checkSell() {
      await this.approve(this.tokenOutInfo.address);
      this._refreshCheckBuyIntervel = setInterval(() => {
        this._checkSell();
      }, 3000);
    },
    async _checkSell() {
      // if(!this.buyMsg) {
      //   this.buyMsg = this.$Message.loading({
      //   content: "检测卖出中...",
      //   duration: 0,
      // });
      // }

      try {
        let wallet = this.wallet;
        var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
        const swapContract = new myWeb3.eth.Contract(
          SwapRouter_ABI,
          this.swapRouter
        );

        let path = [];
        //当前选择的交易币种
        let selectToken = this.selectToken;
        //池子代币，烧gas模式用指定池子，否则用检测到的池子
        let pairOther = this.selectPoolToken.address;
        //当前代币合约信息
        let tokenOutInfo = this.tokenOutInfo;
        //不是烧gas模式，用检测的池子
        if (!this.gasMode) {
          pairOther = tokenOutInfo.pairOther;
        }
        //路径，当前代币
        path.push(tokenOutInfo.address);
        //池子
        if (pairOther != selectToken.address) {
          path.push(pairOther);
        }
        //指定支付代币
        path.push(selectToken.address);

        //代币余额
        let tokenBalance = this.wallet.tokenBalance;
        //卖出比例
        let saleRate = this.saleRate;
        if (!saleRate) {
          saleRate = "50";
        }
        saleRate = new BN(parseInt(saleRate));
        //卖出数量
        let amountIn = tokenBalance.mul(saleRate).div(new BN(100));

        //购买支付的数量
        let payAmount = this.payAmount;
        //根据价格上涨计算卖出代币得到总回报
        let priceUp = this.priceUp;
        if (!priceUp) {
          priceUp = "100";
        }
        priceUp = new BN(parseInt(priceUp)).add(new BN(100));
        //默认是翻倍卖一半，算上滑点
        //全部卖出得到的回报
        let allSellReceiveAmount = payAmount.mul(priceUp).div(new BN(100));
        //卖出比例该得到的回报
        let amountOutMin = allSellReceiveAmount.mul(saleRate).div(new BN(100));

        let data;
        //卖得主币
        if (selectToken.address == this.chainConfig.WETH) {
          console.log("2222a");
          console.log(JSON.stringify(path));
          console.log(wallet.address);
          data = swapContract.methods
            .swapExactTokensForETHSupportingFeeOnTransferTokens(
              amountIn,
              amountOutMin,
              path,
              wallet.address,
              1914823077
            )
            .encodeABI();
        } else {
          console.log("2222b");
          console.log(JSON.stringify(path));
          console.log(wallet.address);
          data = swapContract.methods
            .swapExactTokensForTokensSupportingFeeOnTransferTokens(
              amountIn,
              amountOutMin,
              path,
              wallet.address,
              1914823077
            )
            .encodeABI();
        }

        let gas;
        //卖得主币
        if (selectToken.address == this.chainConfig.WETH) {
          console.log("3333a");
          gas = await swapContract.methods
            .swapExactTokensForETHSupportingFeeOnTransferTokens(
              amountIn,
              amountOutMin,
              path,
              wallet.address,
              1914823077
            )
            .estimateGas({ from: wallet.address });
        } else {
          console.log("3333b");
          gas = await swapContract.methods
            .swapExactTokensForTokensSupportingFeeOnTransferTokens(
              amountIn,
              amountOutMin,
              path,
              wallet.address,
              1914823077
            )
            .estimateGas({ from: wallet.address });
        }
        console.log("44444");
        var nonce = await myWeb3.eth.getTransactionCount(
          wallet.address,
          "pending"
        );
        console.log("nonce", nonce);

        let value = "0";

        //这里gasLimit直接用200万
        gas = new BN(this.gasLimit, 10);

        let gasPrice = await myWeb3.eth.getGasPrice();
        gasPrice = new BN(gasPrice, 10);
        //gas倍数
        let gasMulti = this.gasMulti;
        if (!gasMulti) {
          gasMulti = 1;
        }
        gasMulti = parseFloat(gasMulti);
        gasMulti = parseInt(gasMulti * 100);
        gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));

        var txParams = {
          gas: Web3.utils.toHex(gas),
          gasPrice: Web3.utils.toHex(gasPrice),
          nonce: Web3.utils.toHex(nonce),
          chainId: this.chainConfig.ChainId,
          value: Web3.utils.toHex(value),
          to: this.swapRouter,
          data: data,
          from: wallet.address,
        };

        var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));
        console.log("fee", Web3.utils.fromWei(fee, "ether"));

        //交易签名
        let privateKey = wallet.privateKey;
        var signedTx = await myWeb3.eth.accounts.signTransaction(
          txParams,
          privateKey
        );
        console.log("signedTx", signedTx);
        console.log("txParams", txParams);
        this.clearCheckBuyInterval();
        let transaction = await myWeb3.eth.sendSignedTransaction(
          signedTx.rawTransaction
        );
        // 交易失败
        if (!transaction.status) {
          this.$message.error("卖出失败");
          return;
        }
        console.log("卖出成功");
        this.$message("卖出成功");

        this.isLoading = false;
        this.message.close();
        this.getWalletBalance(wallet);
      } catch (e) {
        console.log("e", e);
      } finally {
        // if(this.buyMsg) {
        //   this.buyMsg()
        //   this.buyMsg = null
        // }
      }
    },
  },
};
</script>

<style lang="less">
@import "../assets/pc/scientist.less";
@import "../assets/mobile/scientist.less";
</style>
